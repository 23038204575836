var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isHomeUser
    ? _c("div", { staticClass: "kt-widget__media" }, [
        _c("div", { staticClass: "kt-widget__img" }, [
          _c(
            "div",
            {
              staticClass:
                "kt-widget__pic kt-widget__pic--info kt-font-info kt-font-boldest",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    (
                      _vm.student.maskedStudentName || _vm.student.lastName
                    ).substring(0, 2)
                  ) +
                  " "
              ),
            ]
          ),
        ]),
      ])
    : _c(
        "div",
        { staticClass: "kt-widget__media" },
        [
          _vm.encryptionEnabled
            ? _c("StudentAvatar", {
                staticStyle: { "min-width": "96px" },
                attrs: {
                  student: _vm.student,
                  "student-avatar": _vm.student.studentAvatar,
                },
              })
            : _c(
                "UserAvatar",
                {
                  staticClass: "kt-widget__img",
                  attrs: { "avatar-user": _vm.student },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "kt-widget__pic kt-widget__pic--info kt-font-info kt-font-boldest",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            (
                              _vm.student.maskedStudentName ||
                              _vm.student.lastName
                            ).substring(0, 2)
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }