<template>
<div
    v-if="isHomeUser"
    class="kt-widget__media"
>
    <div class="kt-widget__img">
        <div class="kt-widget__pic kt-widget__pic--info kt-font-info kt-font-boldest">
            {{ (student.maskedStudentName || student.lastName).substring(0, 2) }}
        </div>
    </div>
</div>
<div
    v-else
    class="kt-widget__media"
>
    <StudentAvatar
        v-if="encryptionEnabled"
        :student="student"
        :student-avatar="student.studentAvatar"
        style="min-width: 96px"
    />

    <UserAvatar
        v-else
        :avatar-user="student"
        class="kt-widget__img"
    >
        <div class="kt-widget__pic kt-widget__pic--info kt-font-info kt-font-boldest">
            {{ (student.maskedStudentName || student.lastName).substring(0, 2) }}
        </div>
    </UserAvatar>
</div>
</template>

<script>

import StudentAvatar from './StudentAvatar.vue';
import UserAvatar from './UserAvatar.vue';
import userMixins from '../store/mixins/userMixins';

export default {
    name: 'StudentPortfolioAvatar',
    components: {
        StudentAvatar,
        UserAvatar,
    },
    mixins: [
        userMixins,
    ],
    props: {
        student: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isHomeUser() {
            const isHomeUser = this.$_userMixins_isHomeUser;
            return this.forceStudentView ? true : isHomeUser;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        clientSideEncryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled;
        },
        serverSideEncryptionEnabled() {
            return this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        wideMode() {
            if (this.displayWide) {
                if (this.deviceType === 'mobile') return false;
                return true;
            }
            return this.deviceType === 'tablet';
        },
    },
    mounted() {
    },
    methods: {
    },
};

</script>

<style scoped>

div.kt-widget__media {
    width: 90px;
    height: 90px;
}
img.kt-widget__img {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: 90px;
    min-width: 68px;
}

img.big-picture {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: auto;
}
</style>
