var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kt-subheader kt-grid__item",
      attrs: { id: "kt_subheader" },
    },
    [
      _c("div", { staticClass: "kt-container kt-container--fluid" }, [
        _c(
          "div",
          { staticClass: "kt-subheader__main" },
          [
            _c(
              "h3",
              { staticClass: "kt-subheader__title" },
              [_vm._t("title")],
              2
            ),
            _vm._t("left-buttons"),
          ],
          2
        ),
        _c("div", { staticClass: "kt-subheader__toolbar" }, [
          _c(
            "div",
            { staticClass: "kt-subheader__wrapper" },
            [_vm._t("buttons")],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }