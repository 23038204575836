<template>
<div
    id="kt_subheader"
    class="kt-subheader kt-grid__item"
>
    <div class="kt-container kt-container--fluid">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <slot name="title" />
            </h3>
            <slot name="left-buttons" />
        </div>

        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <slot name="buttons" />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

export default {
    name: 'GeneralSubHeader',
    components: {
    },
    mixins: [
    ],
    props: {

    },
};

</script>
